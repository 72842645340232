import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import type { SurveyQuestion, SurveyChoice } from '../schemas'
import { useTranslation } from 'react-i18next'
import StarRating from './StarRating'

interface Props {
  question: SurveyQuestion
  onChange: (questionId: number, value: string | number) => void
  value?: string | number
  disabled?: boolean
}

const QuestionInput = ({ question, onChange, value, disabled }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (newValue: string | number) => {
    onChange(question.id, question.answerType === 'MULTIPLE_CHOICE' ? Number(newValue) : newValue)
  }

  const getOptionStyles = (optionValue: string | number) => ({
    margin: 0,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    py: 1,
    px: 2,
    transition: 'all 0.2s ease',
    width: '100%',
    '&:hover': !disabled && {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.primary.main,
    },
    ...(value === optionValue && {
      backgroundColor: `${theme.palette.primary.main}15`,
      borderColor: theme.palette.primary.main,
      '& .MuiFormControlLabel-label': {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      '& .MuiRadio-root': {
        color: theme.palette.primary.main,
      },
    }),
    '& .MuiRadio-root': {
      padding: '4px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: isMobile ? theme.typography.body1.fontSize : 'inherit',
      fontWeight: value === optionValue ? 500 : 400,
    },
  })

  switch (question.answerType) {
    case 'STAR_RATING':
      return (
        <StarRating
          value={Number(value) || 0}
          onChange={(newValue) => handleChange(newValue.toString())}
          disabled={disabled}
        />
      )

    case 'YES_NO':
      return (
        <RadioGroup
          value={value ?? ''}
          onChange={(e) => handleChange(e.target.value)}
          row={!isMobile}
          sx={{
            gap: 1,
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)',
            '& .MuiFormControlLabel-root': {
              margin: 0,
            },
          }}
        >
          <FormControlLabel
            value='yes'
            control={<Radio disabled={disabled} />}
            label={t('common.shared.yes')}
            sx={getOptionStyles('yes')}
          />
          <FormControlLabel
            value='no'
            control={<Radio disabled={disabled} />}
            label={t('common.shared.no')}
            sx={getOptionStyles('no')}
          />
        </RadioGroup>
      )

    case 'MULTIPLE_CHOICE':
      return (
        <RadioGroup
          value={value ?? ''}
          onChange={(e) => handleChange(e.target.value)}
          sx={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : 'repeat(auto-fit, minmax(200px, 1fr))',
            gap: 1,
          }}
        >
          {question.choices?.map((choice: SurveyChoice) => (
            <FormControlLabel
              key={choice.id}
              value={choice.id}
              control={<Radio disabled={disabled} />}
              label={t(`survey.questions.order_type.choices.${choice.choiceText.toLowerCase()}`)}
              sx={getOptionStyles(choice.id)}
            />
          ))}
        </RadioGroup>
      )

    case 'TEXT':
      return (
        <TextField
          fullWidth
          multiline
          rows={isMobile ? 4 : 3}
          value={value ?? ''}
          onChange={(e) => handleChange(e.target.value)}
          disabled={disabled}
          placeholder={t('survey.form.placeholder')}
          sx={{
            '& .MuiInputBase-root': {
              fontSize: isMobile ? theme.typography.body1.fontSize : 'inherit',
              backgroundColor: theme.palette.background.paper,
            },
          }}
        />
      )

    default:
      return null
  }
}

const SurveyQuestion = ({ question, onChange, value, disabled }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  // Get the translation key based on the question text
  const getTranslationKey = (questionText: string) => {
    const questionMap: { [key: string]: string } = {
      'What order type did you choose?': 'order_type',
      'Was the driver punctual within the one hour time slot?': 'punctuality',
      'Was the delay notified to you?': 'delay_notification',
      'Was the car clean outside (considering the weather conditions)?': 'car_clean_outside',
      'Was the car clean inside (considering the weather conditions)?': 'car_clean_inside',
      'Were you satisfied with the car explanation (basic functionalities)?': 'car_explanation',
      'How would you rate your overall experience?': 'overall_experience',
    }

    const key = questionMap[questionText]
    return key ? `survey.questions.${key}.question` : questionText
  }

  return (
    <Box sx={{ mb: 3 }}>
      <FormControl fullWidth>
        <FormLabel
          sx={{
            mb: 2,
            color: 'text.primary',
            '&.Mui-focused': { color: 'text.primary' },
          }}
        >
          <Typography
            variant={isMobile ? 'subtitle1' : 'h6'}
            component='div'
            sx={{
              fontWeight: 600,
              lineHeight: 1.3,
              ...(isMobile && {
                fontSize: '1.125rem',
              }),
            }}
          >
            {t(getTranslationKey(question.question))}
          </Typography>
        </FormLabel>
        <QuestionInput question={question} onChange={onChange} value={value} disabled={disabled} />
      </FormControl>
    </Box>
  )
}

export default SurveyQuestion
