import { Box, Container, Paper, Typography, useTheme, useMediaQuery } from '@mui/material'
import { PropsWithChildren } from 'react'
import LanguageSwitch from './LanguageSwitch'
import { useTranslation } from 'react-i18next'

interface Props extends PropsWithChildren {
  clientName?: string
}

const SurveyLayout = ({ children, clientName }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        // minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        bgcolor: theme.palette.grey[50],
      }}
    >
      {/* Header */}
      <Paper
        elevation={0}
        sx={{
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 0,
          borderBottom: `1px solid ${theme.palette.divider}`,
          position: 'relative', // For absolute positioning of language switch
        }}
      >
        <LanguageSwitch />

        <img
          src='/images/logo.png'
          alt='logo'
          style={{
            height: isMobile ? 32 : 40,
            marginBottom: theme.spacing(2),
          }}
        />
        <Typography
          variant={isMobile ? 'body1' : 'h6'}
          align='center'
          color='text.secondary'
          sx={{ maxWidth: 600 }}
        >
          {clientName
            ? t('survey.header.greeting_with_name', { name: clientName })
            : t('survey.header.greeting')}
        </Typography>
      </Paper>

      {/* Main Content */}
      <Container
        maxWidth='md'
        sx={{
          flex: 1,
          py: { xs: 2, sm: 4 },
          px: { xs: 2, sm: 3 },
        }}
      >
        {children}
      </Container>

      {/* Footer */}
      <Box
        component='footer'
        sx={{
          py: 3,
          px: 2,
          backgroundColor: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Container maxWidth='md'>
          <Typography variant='body2' color='text.secondary' align='center'>
            © {new Date().getFullYear()} ALB. {t('survey.footer.rights')}
          </Typography>
        </Container>
      </Box>
    </Box>
  )
}

export default SurveyLayout
