import { z } from 'zod'
import { jwtDecode } from 'jwt-decode'

// Define the token payload schema
const deliveryRatingTokenSchema = z.object({
  deliveryId: z.number(),
  clientFirstName: z.string(),
  clientLastName: z.string(),
  exp: z.number(),
  // Remove rated field as we'll check it via API
})

// Type for the token payload
export type DeliveryRatingToken = z.infer<typeof deliveryRatingTokenSchema>

export enum TokenValidationError {
  NO_TOKEN = 'NO_TOKEN',
  INVALID_FORMAT = 'INVALID_FORMAT',
  EXPIRED = 'EXPIRED',
  ALREADY_RATED = 'ALREADY_RATED',
  DELIVERY_NOT_FOUND = 'DELIVERY_NOT_FOUND',
}

export interface TokenParseResult {
  isValid: boolean
  token: DeliveryRatingToken | null
  error?: TokenValidationError
}

/**
 * Parses and validates the basic structure of the delivery rating token
 * Does not check if the delivery is rated - that's handled by the API
 */
export const parseDeliveryRatingToken = (): TokenParseResult => {
  // Get the token from URL query params
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')

  if (!token) {
    return {
      isValid: false,
      token: null,
      error: TokenValidationError.NO_TOKEN,
    }
  }

  // Decode the JWT token
  const decoded = jwtDecode<DeliveryRatingToken>(token)

  // Validate the token structure
  const result = deliveryRatingTokenSchema.safeParse(decoded)

  if (!result.success) {
    console.error('Invalid delivery rating token structure:', result.error)
    return {
      isValid: false,
      token: null,
      error: TokenValidationError.INVALID_FORMAT,
    }
  }

  // Check if token is expired
  if (result.data.exp * 1000 < Date.now()) {
    return {
      isValid: false,
      token: result.data,
      error: TokenValidationError.EXPIRED,
    }
  }

  return {
    isValid: true,
    token: result.data,
  }
}

/**
 * Gets the full name from the token
 */
export const getClientFullName = (): string | null => {
  const { token } = parseDeliveryRatingToken()
  if (!token) return null

  return `${token.clientFirstName} ${token.clientLastName}`.trim()
}

/**
 * Gets the delivery ID from the token
 */
export const getDeliveryId = (): number | null => {
  const { token } = parseDeliveryRatingToken()
  return token?.deliveryId ?? null
}
