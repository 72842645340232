import { Box, CircularProgress, Typography, Skeleton, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'

const RatingLoading = () => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        py: 6,
        px: 3,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 500,
          width: '100%',
        }}
      >
        <CircularProgress size={60} thickness={4} sx={{ mb: 3 }} />

        <Typography variant='h6' gutterBottom color='primary' fontWeight={500}>
          {t('survey.loading.title')}
        </Typography>

        <Typography color='text.secondary' paragraph>
          {t('survey.loading.description')}
        </Typography>

        {/* Skeleton placeholders for survey questions */}
        <Box sx={{ width: '100%', mt: 2 }}>
          <Skeleton variant='rectangular' height={60} sx={{ borderRadius: 1, mb: 2 }} />
          <Skeleton variant='rectangular' height={40} width='80%' sx={{ borderRadius: 1, mb: 1 }} />
          <Skeleton variant='rectangular' height={40} width='60%' sx={{ borderRadius: 1, mb: 3 }} />

          <Skeleton variant='rectangular' height={60} sx={{ borderRadius: 1, mb: 2 }} />
          <Skeleton variant='rectangular' height={40} width='70%' sx={{ borderRadius: 1, mb: 3 }} />

          <Skeleton variant='rectangular' height={50} width='40%' sx={{ borderRadius: 1, mt: 2 }} />
        </Box>
      </Paper>
    </Box>
  )
}

export default RatingLoading
