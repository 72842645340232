import { useTranslation } from 'react-i18next'

type Props = {
  error: string
}

const RatingError = ({ error }: Props) => {
  const { t } = useTranslation()

  return (
    <div className='p-4 text-center'>
      <h3 className='text-xl font-semibold text-red-600 mb-2'>{t('common.error')}</h3>
      <p>{t(`delivery.rating.errors.${error}`, { fallback: error })}</p>
    </div>
  )
}

export default RatingError
