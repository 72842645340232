import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Paper, Chip, CircularProgress } from '@mui/material'
import { useGetDeliveryResponses } from '../queries'
import { SurveyQuestion, SurveyResponse, SurveyChoice } from '../schemas'
import StarRating from './StarRating'

interface SurveyResultsProps {
  deliveryId: number
}

const SurveyResults: React.FC<SurveyResultsProps> = ({ deliveryId }) => {
  const { t } = useTranslation()
  const [_questions, _choices, _responses] = useGetDeliveryResponses(deliveryId)
  if (_questions.isLoading || _choices.isLoading || _responses.isLoading) {
    return <CircularProgress />
  }
  if (
    _questions.error ||
    _choices.error ||
    _responses.error ||
    !_questions.data ||
    !_choices.data ||
    !_responses.data ||
    _responses.data.length === 0
  ) {
    return <Typography color='error'>{t('common.shared.no-data')}</Typography>
  }
  const questions: SurveyQuestion[] = _questions.data
  const choices: SurveyChoice[] = _choices.data
  const responses: SurveyResponse[] = _responses.data
  console.log({ questions, choices, responses })

  // Function to determine if a conditional question should be displayed
  const shouldDisplayQuestion = (question: SurveyQuestion): boolean => {
    // If the question doesn't have a conditional dependency, always show it
    if (!question.conditionalOnId) {
      return true
    }

    // Find the parent question that this question depends on
    const parentQuestion = questions.find((q) => q.id === question.conditionalOnId)
    if (!parentQuestion) {
      return true // If parent not found, show the question
    }

    // Find the response to the parent question
    const parentResponse = responses.find((r) => r.questionId === parentQuestion.id)
    if (!parentResponse) {
      return false // If no response to parent, don't show the conditional question
    }

    // For YES_NO questions, check if the condition matches
    if (parentQuestion.answerType === 'YES_NO') {
      // If the parent question was about punctuality and the answer was "yes",
      // we don't need to show questions about delay notification
      if (
        parentQuestion.question.toLowerCase().includes('punctual') &&
        parentResponse.responseText === 'yes'
      ) {
        return false
      }

      // Add other conditional logic here as needed
      // Check the condition field if it exists
      if (question.condition) {
        // If condition is "yes" but response is "no" or vice versa, don't show
        if (question.condition === 'yes' && parentResponse.responseText !== 'yes') {
          return false
        }
        if (question.condition === 'no' && parentResponse.responseText !== 'no') {
          return false
        }
      }
    }

    return true
  }

  const renderAnswer = (question: SurveyQuestion, answer: SurveyResponse | undefined) => {
    if (!answer) return null

    let choice
    switch (question.answerType) {
      case 'STAR_RATING':
        return (
          <Box sx={{ mt: 1 }}>
            <StarRating
              value={Number(answer.responseText) || 0}
              disabled
              onChange={() => {}} // Disabled, so onChange is not needed
            />
          </Box>
        )
      case 'YES_NO':
        return (
          <Chip
            label={answer.responseText === 'yes' ? t('common.shared.yes') : t('common.shared.no')}
            color={answer.responseText === 'yes' ? 'success' : 'error'}
            size='small'
            sx={{ mt: 1 }}
          />
        )
      case 'MULTIPLE_CHOICE':
        choice = question.choices?.find((c) => c.id === answer.choiceId)
        return choice ? (
          <Box sx={{ mt: 1 }}>
            <Chip label={choice.choiceText} color='primary' size='small' />
          </Box>
        ) : null
      default:
        return null
    }
  }

  return (
    <Box>
      {questions
        .filter((question) => shouldDisplayQuestion(question))
        .map((question) => {
          const answer = responses.find((r) => r.questionId === question.id)
          return (
            <Paper
              key={question.id}
              elevation={2}
              sx={{
                p: 2,
                mb: 2,
                backgroundColor: '#f8f9fa',
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                {question.question}
              </Typography>
              {renderAnswer(question, answer)}
            </Paper>
          )
        })}
    </Box>
  )
}

export default SurveyResults
