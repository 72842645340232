import { useState } from 'react'
import { useGetSurveyQuestions, useCheckDeliveryRated } from './queries'
import RatingLoading from './components/loading'
import RatingError from './Error'
import SurveyForm from './components/SurveyForm'
import SurveyLayout from './components/SurveyLayout'
import { TokenValidationError } from '@utils/delivery-rating'
import { Alert, Box, Button, Typography } from '@mui/material'
import { Email as EmailIcon } from '@mui/icons-material'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

const getErrorMessage = (
  error: TokenValidationError,
  t: TFunction,
): { title: string; description: string } => {
  switch (error) {
    case TokenValidationError.EXPIRED:
      return {
        title: t('survey.error.title.expired'),
        description: t('survey.error.description.expired'),
      }
    case TokenValidationError.DELIVERY_NOT_FOUND:
      return {
        title: t('survey.error.title.delivery_not_found'),
        description: t('survey.error.description.delivery_not_found'),
      }
    case TokenValidationError.NO_TOKEN:
    case TokenValidationError.INVALID_FORMAT:
    default:
      return {
        title: t('survey.error.title.invalid'),
        description: t('survey.error.description.invalid'),
      }
  }
}

const TokenError = ({ error }: { error: TokenValidationError }) => {
  const { t } = useTranslation()
  const errorInfo = getErrorMessage(error, t)

  return (
    <Box sx={{ textAlign: 'center', maxWidth: 600, mx: 'auto' }}>
      <Typography variant='h5' gutterBottom color='error'>
        {errorInfo.title}
      </Typography>
      <Typography color='text.secondary' paragraph>
        {errorInfo.description}
      </Typography>
      <Alert severity='info' sx={{ mb: 3, textAlign: 'left' }}>
        {t('survey.error.contact_support')}
      </Alert>
      <Button variant='contained' startIcon={<EmailIcon />} href='mailto:support@alb.com'>
        {t('common.shared.contact-support')}
      </Button>
    </Box>
  )
}

const ThankYouContent = () => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        textAlign: 'center',
        maxWidth: 600,
        mx: 'auto',
        py: { xs: 4, sm: 6 },
      }}
    >
      <Typography variant='h4' gutterBottom color='primary' sx={{ fontWeight: 600 }}>
        {t('survey.thank_you.title')}
      </Typography>

      <Typography variant='h6' color='text.secondary' paragraph>
        {t('survey.thank_you.description')}
      </Typography>

      <Typography color='text.secondary' paragraph>
        {t('survey.thank_you.additional_info')}
      </Typography>
    </Box>
  )
}

const DeliveryRating = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const { t } = useTranslation()

  // Get token and check if already rated
  const {
    rated,
    data: parseResult,
    isLoading: isCheckingRated,
    error: ratedError,
  } = useCheckDeliveryRated()

  // Get survey questions
  const {
    data: questions,
    isLoading: isLoadingQuestions,
    error: questionsError,
  } = useGetSurveyQuestions()

  // Show loading state while validating token and checking rated status
  if (!parseResult || (parseResult.isValid && isCheckingRated)) {
    return (
      <SurveyLayout>
        <RatingLoading />
      </SurveyLayout>
    )
  }

  // Determine what content to show
  let content: JSX.Element

  // Invalid token
  if (!parseResult.isValid) {
    content = <TokenError error={parseResult.error!} />
  }
  // Delivery not found error
  else if (ratedError && (ratedError as AxiosError)?.response?.status === 404) {
    content = <TokenError error={TokenValidationError.DELIVERY_NOT_FOUND} />
  }
  // Already rated or just submitted
  else if (rated || hasSubmitted) {
    content = <ThankYouContent />
  }
  // Loading questions
  else if (isLoadingQuestions) {
    content = <RatingLoading />
  }
  // Error loading questions
  else if (questionsError || !questions) {
    content = (
      <RatingError error={(questionsError as Error)?.message || t('network.errors.unknown')} />
    )
  }
  // Show survey form
  else {
    content = (
      <SurveyForm
        questions={questions}
        deliveryId={parseResult.token!.deliveryId}
        onComplete={() => setHasSubmitted(true)}
      />
    )
  }

  return <SurveyLayout clientName={parseResult.token?.clientFirstName}>{content}</SurveyLayout>
}

export default DeliveryRating
