import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { toast } from 'react-toastify'
import {
  Box,
  Divider,
  Grid,
  Typography,
  Paper,
  LinearProgress,
  Alert,
  IconButton,
  Tooltip,
  Theme,
  SxProps,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import { Doc } from '@types_def/models/delivery.types'
import { deleteDeliveryDoc } from '../Delivery/DeliveryFormV2/Querys'
import FilesGrid from './FilesGrid'

export type FileUploadProps = {
  /** Field name in the form */
  name?: string
  /** Whether to allow file deletion */
  remove?: boolean
  /** Force edit mode regardless of other conditions */
  forceAllowEdit?: boolean
  /** ID used for file path construction */
  id?: string | number
  /** Initial files to display */
  initialValue?: Doc[]
  /** Maximum file size in MB */
  maxFileSize?: number
  /** Maximum number of files allowed */
  maxFiles?: number
  /** Accepted file types */
  acceptedFileTypes?: Record<string, string[]>
  /** Custom upload path */
  uploadPath?: string
  /** Whether to show the clear all button */
  showClearAll?: boolean
  /** Custom error messages */
  errorMessages?: {
    maxSize?: string
    maxFiles?: string
    fileType?: string
  }
}

const DEFAULT_MAX_FILE_SIZE = 50 // MB
const DEFAULT_MAX_FILES = 10
const DEFAULT_ACCEPTED_TYPES = { 'application/pdf': ['.pdf'] }

const getFileName = (key: string): string => {
  // Extract the filename from the path
  const parts = key.split('/')
  return parts[parts.length - 1]
}

const getUploadAreaStyles = (
  isDisabled: boolean,
  hasError: boolean,
  isDragging: boolean,
): SxProps<Theme> => ({
  minHeight: 180,
  border: '2px dashed',
  borderColor: isDragging
    ? 'primary.main'
    : isDisabled
      ? 'grey.400'
      : hasError
        ? 'error.main'
        : 'grey.300',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: isDisabled ? 'not-allowed' : 'pointer',
  position: 'relative',
  bgcolor: isDisabled ? 'grey.100' : 'background.paper',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    borderColor: isDisabled ? 'grey.400' : 'primary.main',
    bgcolor: isDisabled ? 'grey.100' : 'action.hover',
  },
})

const FileUploadForm: React.FC<FileUploadProps> = ({
  name = 'docs',
  remove = true,
  forceAllowEdit = undefined,
  id = undefined,
  initialValue = [],
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  maxFiles = DEFAULT_MAX_FILES,
  acceptedFileTypes = DEFAULT_ACCEPTED_TYPES,
  uploadPath,
  showClearAll = true,
  errorMessages = {},
}) => {
  const { t } = useTranslation()
  const { field, fieldState } = useController({
    name,
    rules: { required: true },
  })

  const [uploadedFiles, setUploadedFiles] = useState<Doc[]>(() => {
    const value = field.value as Doc[]
    if (!value || value.length === 0) {
      // Ensure initialValue has proper structure
      return initialValue.map((doc) => ({
        name: doc.key ? getFileName(doc.key) : doc.name,
        key: doc.key,
        url: doc.url,
      }))
    }
    const oldDocIndex = value.findIndex((doc) => doc.name?.includes('Handover'))
    if (oldDocIndex >= 0) {
      value.splice(oldDocIndex, 1)
    }
    // Ensure proper structure for both initial and form values
    const processedValue = value.map((doc) => ({
      name: doc.key ? getFileName(doc.key) : doc.name,
      key: doc.key,
      url: doc.url,
    }))

    return [...initialValue, ...processedValue]
  })

  const [loading, setLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    if (JSON.stringify(field.value) !== JSON.stringify(uploadedFiles)) {
      field.onChange(uploadedFiles)
    }
  }, [uploadedFiles, field])

  const handleError = (message: string) => {
    setError(true)
    setErrorMessage(message)
    toast.error(message, { position: 'bottom-right' })
  }

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // Handle rejected files
      if (fileRejections.length > 0) {
        const rejection = fileRejections[0]
        if (rejection.errors[0]?.code === 'file-too-large') {
          handleError(errorMessages.maxSize || t('errors.file.size', { size: maxFileSize }))
        } else if (rejection.errors[0]?.code === 'file-invalid-type') {
          handleError(errorMessages.fileType || t('errors.file.type'))
        }
        return
      }

      // Check max files limit
      if (uploadedFiles.length + acceptedFiles.length > maxFiles) {
        handleError(errorMessages.maxFiles || t('errors.file.maxCount', { count: maxFiles }))
        return
      }

      setLoading(true)
      setError(false)
      setErrorMessage(null)
      field.onBlur()

      try {
        const newDocs = await Promise.all(
          acceptedFiles.map(async (file) => {
            const formData = new FormData()
            formData.append('file', file)

            const key =
              uploadPath ||
              (id && forceAllowEdit
                ? `deliveryDocs/delivery-${id}/validationDocs/${file.name}`
                : `docs/${Date.now()}-${file.name}`)

            formData.append('key', key)

            const { data } = await axios.post('/s3/upload-files', formData, {
              onUploadProgress: (progressEvent) => {
                const progress = progressEvent.total
                  ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                  : 0
                setUploadProgress(progress)
              },
            })

            // Ensure proper structure for new uploads
            return {
              name: getFileName(data.Key),
              key: data.Key,
              url: data.Location,
            }
          }),
        )

        const uniqueFiles = newDocs.filter(
          (newFile) => !uploadedFiles.some((existingFile) => existingFile.key === newFile.key),
        )

        setUploadedFiles((prevFiles) => [...prevFiles, ...uniqueFiles])
        toast.success(t('network.delivery.documents.uploaded'), { position: 'bottom-right' })
      } catch (error) {
        console.error('Error uploading files:', error)
        handleError(t('network.errors.unknown'))
      } finally {
        setLoading(false)
        setUploadProgress(0)
      }
    },
    [uploadedFiles, id, forceAllowEdit, t, field, maxFiles, uploadPath, errorMessages, maxFileSize],
  )

  const changeFileState = useCallback(
    async (file: Doc, deleted: boolean) => {
      if (remove && file.key) {
        try {
          await deleteDeliveryDoc(file.key)
          setUploadedFiles((prevFiles) => prevFiles.filter((f) => f.key !== file.key))
          toast.success(t('network.delivery.documents.deleted'))
        } catch (error) {
          console.error('Error deleting file:', error)
          handleError(t('network.errors.unknown'))
        }
      } else {
        setUploadedFiles((prevFiles) =>
          prevFiles.map((f) => (f.key === file.key ? { ...f, deleted } : f)),
        )
      }
    },
    [remove, t],
  )

  const handleClearAll = () => {
    if (window.confirm(t('common.confirmations.clearFiles'))) {
      setUploadedFiles([])
      field.onChange([])
    }
  }

  const disabled = forceAllowEdit !== undefined ? !forceAllowEdit : loading
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    disabled,
    maxSize: maxFileSize * 1024 * 1024, // Convert MB to bytes
    multiple: true,
  })

  return (
    <Grid container spacing={2} paddingBottom={2}>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5' textTransform={'capitalize'}>
            {t('delivery.form.documents')}
          </Typography>
          {showClearAll && uploadedFiles.length > 0 && (
            <Tooltip title={t('common.buttons.clearAll')}>
              <IconButton onClick={handleClearAll} color='warning'>
                <ClearAllIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: '#fe5d8d' }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper
          elevation={0}
          ref={field.ref}
          {...getRootProps()}
          sx={getUploadAreaStyles(disabled, error, isDragActive)}
        >
          <input {...getInputProps()} />
          {loading ? (
            <Box sx={{ width: '100%', p: 2 }}>
              <Typography align='center' gutterBottom>
                {t('common.uploading')} ({uploadProgress}%)
              </Typography>
              <LinearProgress variant='determinate' value={uploadProgress} />
            </Box>
          ) : isDragActive ? (
            <Typography variant='h6' color='primary'>
              {t('common.dropFilesHere')}
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                p: 3,
              }}
            >
              {disabled ? (
                <CloudOffIcon fontSize='large' color='disabled' />
              ) : (
                <CloudUploadIcon fontSize='large' color='primary' />
              )}
              <Typography align='center' color={disabled ? 'text.disabled' : 'text.primary'}>
                {t('delivery.form.docs-upload-note')}
              </Typography>
              <Typography variant='caption' color='text.secondary'>
                {t('common.maxFileSize', { size: maxFileSize })}
              </Typography>
            </Box>
          )}
        </Paper>
        {errorMessage && (
          <Alert severity='error' sx={{ mt: 1 }}>
            {errorMessage}
          </Alert>
        )}
        {fieldState.error && (
          <Alert severity='error' sx={{ mt: 1 }}>
            {t('errors.required.files')}
          </Alert>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <FilesGrid changeFileState={changeFileState} disabled={disabled} docs={uploadedFiles} />
      </Grid>
    </Grid>
  )
}

export default FileUploadForm
