import { useState, useEffect } from 'react'
import { Box, ToggleButton, ToggleButtonGroup, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

const LanguageSwitch = () => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState(i18n.language)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // Update the component state when the language changes externally
  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language])

  const handleLanguageChange = (_: React.MouseEvent<HTMLElement>, newLanguage: string | null) => {
    if (newLanguage !== null) {
      i18n.changeLanguage(newLanguage)
      setLanguage(newLanguage)
    }
  }

  return (
    <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
      <ToggleButtonGroup
        value={language}
        exclusive
        onChange={handleLanguageChange}
        aria-label='language selection'
        size={isMobile ? 'small' : 'medium'}
        sx={{
          bgcolor: theme.palette.background.paper,
          '& .MuiToggleButton-root': {
            px: isMobile ? 1 : 2,
            py: isMobile ? 0.5 : 1,
            fontSize: isMobile ? '0.75rem' : '0.875rem',
            fontWeight: 500,
            '&.Mui-selected': {
              bgcolor: `${theme.palette.primary.main}15`,
              color: theme.palette.primary.main,
              fontWeight: 600,
            },
          },
        }}
      >
        <ToggleButton value='en' aria-label='English'>
          EN
        </ToggleButton>
        <ToggleButton value='fr' aria-label='Français'>
          FR
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default LanguageSwitch
