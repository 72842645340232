import { useEffect, useState } from 'react'
import { Params, useParams } from 'react-router-dom'
import { DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import { Grid, Paper, Typography, Stack, Box, Divider } from '@mui/material'
import { useDeliveryDetails } from '@components/Delivery/DeliveryFormV2/Querys'
import Summary from '@components/Delivery/DeliveryFormV2/summary/Summary'
import DeliveryCode from '@components/Delivery/DeliveryFormV2/DeliveryCode'
import ComponentContainer from '@components/Delivery/DeliveryFormV2/ComponentContainer'
import { Mission, PreviewType } from '@components/Map/type'
import EndPointSection from '@components/Delivery/DeliveryDetails/EndPointSection'
import ViewDeliveryButtons from './ViewDeliveryButtons'
import SMSForm from '@components/Delivery/DeliveryFormV2/SMS'
import { strToEnum } from '@utils/strEnum'
import {
  DeliveryQueryFieldsByType,
  DeliveryQueryFieldsByStatus,
} from '@components/Delivery/DeliveryFormV2/Querys/queryFields'
import { useUserContext } from '@contexts/UserContext/index.context'
import { customDeliveryScheduledForDate } from '@utils/functions/Delivery'
import DeliveryLogs from '@components/Delivery/DeliveryFormV2/DeliveryLogs'
import PdfSection from '@components/Delivery/DeliveryDetails/Header/PdfSecrion'
import CustomMap from '@components/Map'
import { UpdateValidationDocs } from '@components/Delivery/DeliveryFormV2/DeliveryFiles'
import MapInstance from '@components/Map/mapInstance'
import PreviewMissionMarkers from '@components/Map/previewMarkers'
import { isOnGoing } from '@components/Map/utils'
import Invoice from '@components/Delivery/invoices'
import { useTranslation } from 'react-i18next'
import FilesGrid from '@components/fileUpload/FilesGrid'
import TrackingSection from '@components/Delivery/DeliveryFormV2/TrackingSection'
import SurveyResults from '@/components/Delivery/DeliveryRating/components/SurveyResults'

const ViewDelivery = () => {
  const { t } = useTranslation()
  const { userState } = useUserContext()

  const { type, contract, status } = useParams<Params>()
  const deliveryType = strToEnum<DeliveryType>(type as string)
  const deliveryStatus = strToEnum<DeliveryStatus>(status as string)
  const fields = {
    ...DeliveryQueryFieldsByType[deliveryType],
    ...DeliveryQueryFieldsByStatus[deliveryStatus],
  }
  const { data, isLoading, isError, error } = useDeliveryDetails(fields, [
    'view',
    type as string,
    status as string,
  ])

  const mission: Mission | any = data
    ? {
        status: data.status,
        origin: {
          lng: data?.fromAddress?.longitude,
          lat: data?.fromAddress?.latitude,
        },
        destination: {
          lng: data?.toAddress?.longitude,
          lat: data?.toAddress?.latitude,
        },
        depart: data?.fromAddress?.address,
        arrive: data?.toAddress?.address,
        color: 'green',
        id: data?.id.toString(),
        restitution: data?.toReturnToAddress?.address,
        toReturn: {
          lng: data?.toReturnToAddress?.longitude,
          lat: data?.toReturnToAddress?.latitude,
        },
        type: data?.type,
        destinationCity: data?.toAddress?.city,
        originCity: data?.fromAddress?.city,
        driver: isOnGoing(data?.status) && {
          id: data?.selectedDriver?.id.toString(),
          name: data?.selectedDriver?.firstName + ' ' + data?.selectedDriver?.lastName,
          avatar: data?.selectedDriver?.avatar,
        },
      }
    : null

  const generateTitle = () => {
    if (data) {
      const courseType = data?.type === DeliveryType.STANDARD ? 'C1' : 'CP'
      if ([DeliveryStatus.PICKUP_CLIENT].includes(data.status))
        return `${t('delivery.titles.delivery')} ${courseType} - № ${contract} | ${t('delivery.titles.in-progress')}`
      if ([DeliveryStatus.PICKUP_DRIVER].includes(data.status))
        return `${t('delivery.titles.delivery')} ${courseType} - № ${contract} | ${t('delivery.titles.driver-took-the-car')}`
      if ([DeliveryStatus.ONGOING].includes(data.status))
        return `${t('delivery.titles.delivery')} ${courseType} - № ${contract} | ${t('delivery.titles.car-on-the-way')}`
      return `${t('delivery.titles.delivery')} ${courseType} - № ${contract}`
    }
  }

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 1200)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (isLoading || !data) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>Error: {JSON.stringify(error)}</span>
  }
  const isAdmin = userState && userState['custom:role'] === 'ADMIN'
  const rank = userState?.['custom:rank'] ? Number(userState?.['custom:rank']) : undefined

  const Map =
    data.status === DeliveryStatus.ONGOING ? (
      <CustomMap data={[mission]} liveTracking />
    ) : (
      <MapInstance>
        <PreviewMissionMarkers
          mission={mission}
          previewType={
            deliveryType === DeliveryType.TWO_WAY_DELIVERY ? PreviewType.ABC : PreviewType.AB
          }
        />
      </MapInstance>
    )
  return (
    <Stack
      direction={'row'}
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {!isMobileView && (
        <Box sx={{ display: { xs: 'none', lg: 'block' }, width: '50vw' }}>{Map}</Box>
      )}

      <Grid
        width={'50vw'}
        container
        p={3}
        spacing={3}
        sx={{ width: { xs: '100vw', lg: '50vw' }, overflowY: 'scroll' }}
      >
        <Grid item xs={12}>
          <Typography
            position={'relative'}
            variant='h4'
            fontWeight={600}
            textAlign={'center'}
            textTransform={'capitalize'}
          >
            {generateTitle()}
            {/* {isAdmin ? (
              <EditDeliveryStatus type={data.type} deliveryId={data.id} status={data.status} />
            ) : null} */}
          </Typography>
          <Divider sx={{ margin: 1 }} />
          {isAdmin ? (
            <>
              {/* <Typography variant='body1' textAlign={'center'}>
                Statut : {smsStatus[data?.status]?.smsFormText}
              </Typography> */}

              {data.status === DeliveryStatus.COMPLETED ? (
                <Grid item xs={12}>
                  <PdfSection delivery={data} />
                </Grid>
              ) : null}
              {DeliveryStatus.COMPLETED !== data?.status ? (
                <Grid item xs={12}>
                  <TrackingSection id={data.id.toString()} />
                </Grid>
              ) : null}
            </>
          ) : null}
        </Grid>
        {isMobileView && (
          <Grid item xs={12} sx={{ display: { sm: 'block', lg: 'none', minHeight: 400 } }}>
            {Map}
          </Grid>
        )}
        {[
          DeliveryStatus.CANCELLED,
          DeliveryStatus.CANCELLED_BY_ADMIN,
          DeliveryStatus.CANCELLED_BY_MANDATORY,
          DeliveryStatus.CANCELLED_BY_CUSTOMER,
        ].includes(data.status) ? (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              sx={{
                backgroundColor: '#E7E6F7',
                borderRadius: 1,
                padding: '20px',
                height: '100%',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant='body1' textTransform={'capitalize'}>
                    <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>
                      {t('common.shared.cancelled-by')}{' '}
                    </span>
                    :{' '}
                    {data.status === DeliveryStatus.CANCELLED_BY_ADMIN
                      ? t('common.shared.admin')
                      : data.status === DeliveryStatus.CANCELLED_BY_MANDATORY
                        ? t('common.shared.mandatory')
                        : data.status === DeliveryStatus.CANCELLED_BY_CUSTOMER
                          ? t('common.shared.customer')
                          : t('common.shared.not-specified')}
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={'right'}>
                  <Typography variant='body1' textTransform={'capitalize'}>
                    <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>
                      {t('common.shared.cancelled-at')}{' '}
                    </span>
                    :{' '}
                    {data.cancelledAt
                      ? customDeliveryScheduledForDate(data.cancelledAt)
                      : t('common.shared.not-specified')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>
                    <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>
                      {t('common.shared.reason')}{' '}
                    </span>
                    :{' '}
                    {data.cancelReason?.reason
                      ? t(`delivery.cancelReason.${data.cancelReason.reason}`)
                      : t('common.shared.not-specified')}
                  </Typography>
                  <Typography variant='body1'>
                    <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>
                      {t('common.shared.additional-info')}{' '}
                    </span>
                    :{' '}
                    {data.cancelReason?.additionalInfo
                      ? data.cancelReason.additionalInfo
                      : t('common.shared.not-specified')}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              backgroundColor: '#E7E6F7',
              borderRadius: 1,
              padding: '20px',
              height: '100%',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='body1' textTransform={'capitalize'}>
                  {t('common.shared.net-price')} :{' '}
                  {data?.clientFinalPrice
                    ? data.clientFinalPrice
                    : data?.clientInitialPrice
                      ? data?.clientInitialPrice
                      : t('common.shared.not-specified')}
                  €
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body1' textTransform={'capitalize'}>
                  {t('common.shared.delivery-scheduled-for')}{' '}
                  {data.carDeliveryDeadline
                    ? customDeliveryScheduledForDate(data.carDeliveryDeadline)
                    : 'P/A'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              backgroundColor: '#E7E6F7',
              borderRadius: 1,
              padding: '20px',
              height: '100%',
            }}
          >
            <ComponentContainer title={t('delivery.photos-documents')}>
              <EndPointSection delivery={data} index={0} isAdmin={false} />
              <Divider
                orientation='horizontal'
                sx={{
                  marginY: 2,
                  backgroundColor: '#E7E6F7',
                }}
              />
              {data.type === DeliveryType.TWO_WAY_DELIVERY ? (
                <EndPointSection delivery={data} index={1} isAdmin={false} />
              ) : null}
            </ComponentContainer>
            <Divider
              orientation='horizontal'
              sx={{
                marginY: 2,
                backgroundColor: '#E7E6F7',
              }}
            />
            <Box>
              <Typography variant='body1' textTransform={'capitalize'}>
                {t('delivery.documents-of-departure')}
              </Typography>
              {data.docs?.length > 0 ? (
                <FilesGrid docs={data.docs} disabled />
              ) : (
                <Typography sx={{ margin: 2 }} variant='body1' textTransform={'capitalize'}>
                  {' '}
                  {t('common.shared.no-data')}
                </Typography>
              )}
            </Box>
            <Divider
              orientation='horizontal'
              sx={{
                marginY: 2,
                backgroundColor: '#E7E6F7',
              }}
            />
            <Box>
              <Typography variant='body1' textTransform={'capitalize'}>
                {t('delivery.validation-documents')}
              </Typography>
              {data.validationDocs?.length > 0 ? (
                <FilesGrid docs={data.validationDocs} disabled />
              ) : (
                <Typography sx={{ margin: 2 }} variant='body1' textTransform={'capitalize'}>
                  {' '}
                  {t('common.shared.no-data')}
                </Typography>
              )}
            </Box>
          </Paper>
        </Grid>

        {[DeliveryStatus.COMPLETED].includes(data.status) ? (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              sx={{
                backgroundColor: '#E7E6F7',
                borderRadius: 1,
                padding: '20px',
                height: '100%',
              }}
            >
              <ComponentContainer title={t('common.shared.invoices')}>
                <Invoice invoice={data.invoice} deliveryId={data.id} />
              </ComponentContainer>
            </Paper>
          </Grid>
        ) : null}
        {[
          DeliveryStatus.SET,
          DeliveryStatus.PICKUP_CLIENT,
          DeliveryStatus.PICKUP_DRIVER,
          DeliveryStatus.ONGOING,
        ].includes(data.status) ? (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              sx={{
                backgroundColor: '#E7E6F7',
                borderRadius: 1,
                padding: '20px',
                height: '100%',
              }}
            >
              <DeliveryCode startCode={data.startCode} destinationCode={data.destinationCode} />
            </Paper>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              backgroundColor: '#E7E6F7',
              borderRadius: 1,
              padding: '20px',
              height: '100%',
            }}
          >
            <ComponentContainer title={t('common.shared.summary')}>
              <Summary data={data} isRestitution={data.type === DeliveryType.TWO_WAY_DELIVERY} />
            </ComponentContainer>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              backgroundColor: '#E7E6F7',
              borderRadius: 1,
              padding: '20px',
              height: '100%',
            }}
          >
            <ComponentContainer title={t('common.shared.survey-results')}>
              <SurveyResults deliveryId={data.id} />
            </ComponentContainer>
          </Paper>
        </Grid>

        {[
          DeliveryStatus.REQUESTED,
          DeliveryStatus.PENDING_CUSTOMER_CONFIRMATION,
          DeliveryStatus.CONFIRMED_BY_CUSTOMER,
          DeliveryStatus.SMS_SENT,
        ].includes(data?.status) && isAdmin ? (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              sx={{
                backgroundColor: '#E7E6F7',
                borderRadius: 1,
                padding: '20px',
                height: '100%',
              }}
            >
              <ComponentContainer title='SMS'>
                <SMSForm data={data} />
              </ComponentContainer>
            </Paper>
          </Grid>
        ) : (
          <></>
        )}
        {isAdmin ? (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              sx={{
                backgroundColor: '#E7E6F7',
                borderRadius: 1,
                padding: '20px',
                height: '100%',
              }}
            >
              <ComponentContainer title='Delivery logs'>
                <DeliveryLogs deliveryId={Number(contract)} />
              </ComponentContainer>
            </Paper>
          </Grid>
        ) : null}
        <UpdateValidationDocs data={data} isAdmin={isAdmin} rank={rank} />
        <Grid container spacing={1} item xs={12}>
          <ViewDeliveryButtons
            status={data.status}
            id={data.id}
            type={data.type}
            isAdmin={isAdmin}
            rank={rank}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ViewDelivery
