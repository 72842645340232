import { Box, FormControl, Typography, useTheme, useMediaQuery } from '@mui/material'
import { Rating as Stars } from 'react-simple-star-rating'
import { useTranslation } from 'react-i18next'

interface Props {
  value: number
  onChange: (value: number) => void
  disabled?: boolean
}

const StarRating = ({ value, onChange, disabled }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const getRatingText = (rating: number) => {
    if (!rating) return ''
    switch (rating) {
      case 1:
        return t('survey.rating.very_poor')
      case 2:
        return t('survey.rating.poor')
      case 3:
        return t('survey.rating.average')
      case 4:
        return t('survey.rating.good')
      case 5:
        return t('survey.rating.excellent')
      default:
        return ''
    }
  }

  return (
    <Box sx={{ mb: 3 }}>
      <FormControl fullWidth>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2, flexWrap: 'wrap' }}>
          <Typography
            variant='body1'
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 500,
              minWidth: 80,
              transition: 'color 0.2s ease',
            }}
          >
            {getRatingText(value)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stars
            onClick={onChange}
            initialValue={value}
            size={isMobile ? 48 : 40} // Larger touch targets on mobile
            fillColor='#FFB400'
            emptyColor='#E0E0E0'
            readonly={disabled}
            allowFraction={false}
            transition
            showTooltip={false}
            SVGstyle={{
              display: 'inline-block',
              cursor: 'pointer',
              padding: isMobile ? '8px 4px' : '4px 2px', // Increased padding for better touch targets
            }}
            style={{
              pointerEvents: 'auto',
              touchAction: 'manipulation', // Optimize for touch
            }}
          />
        </Box>
      </FormControl>
    </Box>
  )
}

export default StarRating
