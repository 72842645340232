import { Button, Typography, Container } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import React, { useMemo } from 'react'
import { Delivery, DeliveryStatus, Doc } from '@types_def/models/delivery.types'
import CustomModal from '@components/common/CustomModal'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { updateValidationDocs } from '@components/Delivery/DeliveryDetails/Querys'
import FileUploadForm from '@components/fileUpload'
import { deleteDeliveryDoc } from '@components/Delivery/DeliveryFormV2/Querys'
import { UserRank } from '@types_def/models/user.type'

const validationDocsSchema = z.object({
  id: z.number(),
  validationDocs: z.array(
    z.object({
      name: z.string(),
      key: z.string().optional(),
      url: z.string(),
      deleted: z.boolean().optional(),
    }),
  ),
})

type ValidationDocsFormData = {
  id: number
  validationDocs: Doc[]
}

type Props = {
  data: Delivery
  isAdmin?: boolean
  rank?: UserRank
}

const UpdateValidationDocs = ({ data, isAdmin = false, rank = undefined }: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const canEdit = useMemo(() => {
    if (!data || data.status !== DeliveryStatus.COMPLETED) return false
    if (!isAdmin || !rank) return false
    return [UserRank.SUPER_ADMIN, UserRank.MAINTAINER].includes(rank)
  }, [data, isAdmin, rank])

  const queryClient = useQueryClient()
  const methods = useForm<ValidationDocsFormData>({
    resolver: zodResolver(validationDocsSchema),
    defaultValues: {
      id: data.id,
      validationDocs: data.validationDocs || [],
    },
  })

  if (!canEdit) return null

  const handleUpdateValidationDocs = async (formData: ValidationDocsFormData) => {
    try {
      setIsSubmitting(true)
      const { id, validationDocs } = formData
      const docs = validationDocs.filter((doc: Doc) => !doc.deleted)
      const deletedDocs = validationDocs.filter((doc: Doc) => doc.deleted)

      // Delete files marked for deletion
      await Promise.all(deletedDocs.map((doc) => doc.key && deleteDeliveryDoc(doc.key)))

      // Update validation docs
      await updateValidationDocs({ id, validationDocs: docs }, queryClient)

      methods.reset()
      setIsSubmitting(false)
    } catch (error) {
      console.error('Error updating validation docs:', error)
      setIsSubmitting(false)
    }
  }

  return (
    <Container maxWidth='md' sx={{ padding: 2 }}>
      <CustomModal
        button={
          <Button fullWidth variant='contained' color='primary' disabled={isSubmitting}>
            Modifier Validation Documents
          </Button>
        }
        fullWidth
        title='Modifier les documents de validation'
        confirmText={isSubmitting ? 'Modification en cours...' : 'Modifier'}
        confirmAction={methods.handleSubmit(handleUpdateValidationDocs)}
        disabled={isSubmitting}
        color='primary'
        variant='contained'
        size='md'
        noAction={false}
      >
        <FormProvider {...methods}>
          <Typography variant='body2' color='textSecondary' sx={{ mb: 2 }}>
            Ajoutez ou modifiez les documents de validation pour cette livraison.
          </Typography>
          <FileUploadForm name='validationDocs' forceAllowEdit={true} id={data.id} remove={false} />
        </FormProvider>
      </CustomModal>
    </Container>
  )
}

export { UpdateValidationDocs }
