import { useState, useEffect } from 'react'
import { Paper, Box, Button, CircularProgress, Alert, useTheme, useMediaQuery } from '@mui/material'
import type { SurveyQuestion as SurveyQuestionType } from '../schemas'
import SurveyQuestion from './SurveyQuestion'
import { useSubmitSurveyResponse } from '../queries'
import { useTranslation } from 'react-i18next'

interface Props {
  questions: SurveyQuestionType[]
  deliveryId: number
  onComplete?: () => void
}

const SurveyForm = ({ questions, deliveryId, onComplete }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [responses, setResponses] = useState<Record<number, string | number>>({})
  const [visibleQuestions, setVisibleQuestions] = useState<SurveyQuestionType[]>([])
  const { mutate: submitResponse, isPending } = useSubmitSurveyResponse()

  // Function to determine which questions should be visible based on current responses
  const updateVisibleQuestions = () => {
    const visible = questions.filter((question) => {
      // If question has no conditional, it's always visible
      if (!question.conditionalOnId) {
        return true
      }

      // Find the parent question's response
      const parentResponse = responses[question.conditionalOnId]
      if (!parentResponse) {
        return false
      }

      // Check if the parent response matches the condition
      return parentResponse.toString().toUpperCase() === question.condition?.toUpperCase()
    })

    setVisibleQuestions(visible)
  }

  // Update visible questions whenever responses change
  useEffect(() => {
    updateVisibleQuestions()
  }, [responses])

  // Initialize visible questions on mount
  useEffect(() => {
    updateVisibleQuestions()
  }, [questions])

  const handleChange = (questionId: number, value: string | number) => {
    setResponses((prev) => {
      const newResponses = { ...prev, [questionId]: value }

      // Clear responses to conditional questions when parent answer changes
      const childQuestions = questions.filter((q) => q.conditionalOnId === questionId)
      childQuestions.forEach((child) => {
        delete newResponses[child.id]
      })

      return newResponses
    })
  }

  const handleSubmit = () => {
    const formattedResponses = Object.entries(responses).map(([questionId, value]) => ({
      questionId: Number(questionId),
      ...(typeof value === 'string' ? { responseText: value } : { choiceId: value }),
    }))

    submitResponse(
      {
        deliveryId,
        responses: formattedResponses,
      },
      {
        onSuccess: () => {
          onComplete?.()
        },
      },
    )
  }

  const isComplete = visibleQuestions.every((q) => responses[q.id] !== undefined)

  return (
    <Paper
      elevation={0}
      sx={{
        p: { xs: 2, sm: 3 },
        borderRadius: { xs: 0, sm: 1 },
        ...(isMobile && {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }),
      }}
    >
      {visibleQuestions.map((question, index) => (
        <Box
          key={question.id}
          sx={{
            mb: index === visibleQuestions.length - 1 ? 0 : 4,
            transition: 'all 0.3s ease',
            opacity: isPending ? 0.7 : 1,
          }}
        >
          <SurveyQuestion
            question={question}
            onChange={handleChange}
            value={responses[question.id]}
            disabled={isPending}
          />
          {question.conditionalOnId && (
            <Box
              sx={{
                ml: 2,
                pl: 2,
                borderLeft: `2px solid ${theme.palette.divider}`,
              }}
            />
          )}
        </Box>
      ))}

      {visibleQuestions.length === 0 && (
        <Alert
          severity='info'
          sx={{
            mb: 3,
            borderRadius: { xs: 0, sm: 1 },
          }}
        >
          {t('common.shared.no-data')}
        </Alert>
      )}

      <Button
        variant='contained'
        onClick={handleSubmit}
        disabled={!isComplete || isPending}
        endIcon={isPending ? <CircularProgress size={20} /> : null}
        fullWidth={isMobile}
        size={isMobile ? 'large' : 'medium'}
        sx={{ mt: 3 }}
      >
        {t('survey.form.submit')}
      </Button>
    </Paper>
  )
}

export default SurveyForm
