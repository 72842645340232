import { queryOptions, useMutation, useQueries, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { t } from 'i18next'
import axios from '@api/clients/axios'
import type {
  SurveyQuestion,
  SurveyResponse,
  SurveyAnalytics,
  SubmitSurveyResponseInput,
  SurveyChoice,
} from './schemas'
import { parseDeliveryRatingToken, TokenParseResult } from '@/utils/delivery-rating'
import { useState } from 'react'
import { useEffect } from 'react'

const URL = `${import.meta.env.VITE_APP_API_BASE_URL}delivery-rating/`

const questionsQueryOptions = queryOptions({
  queryKey: ['survey-questions'],
  queryFn: async () => {
    const response = await axios.get<SurveyQuestion[]>(`${URL}questions`)
    return response.data
  },
})

const surveyChoicesQueryOptions = queryOptions({
  queryKey: ['survey-choices'],
  queryFn: async () => {
    const response = await axios.get<SurveyChoice[]>(`${URL}survey-choices`)
    return response.data
  },
})

const surveyResponsesQueryOptions = (deliveryId: number) =>
  queryOptions({
    queryKey: ['survey-responses', deliveryId],
    queryFn: async () => {
      const response = await axios.get<SurveyResponse[]>(`${URL}responses/${deliveryId}`)
      return response.data
    },
  })
/**
 * Hook to fetch survey responses for a specific delivery
 */
export const useGetDeliveryResponses = (deliveryId: number) => {
  return useQueries({
    queries: [
      questionsQueryOptions,
      surveyChoicesQueryOptions,
      surveyResponsesQueryOptions(deliveryId),
    ],
  })
}
/**
 * Hook to fetch active survey questions
 */
export const useGetSurveyQuestions = () => {
  return useQuery(questionsQueryOptions)
}

/**
 * Hook to fetch survey analytics
 */
export const useGetSurveyAnalytics = () => {
  return useQuery({
    queryKey: ['survey-analytics'],
    queryFn: async () => {
      const response = await axios.get<SurveyAnalytics>(`${URL}survey-analytics`)
      return response.data
    },
  })
}

/**
 * Hook to submit survey responses
 */
export const useSubmitSurveyResponse = () => {
  return useMutation({
    mutationFn: async (data: SubmitSurveyResponseInput) => {
      const response = await axios.post(`${URL}responses`, data)
      return response.data
    },
    onSuccess: () => {
      toast(t('survey.response.submitted'), {
        position: 'bottom-right',
        type: 'success',
      })
    },
    onError: (error: unknown) => {
      toast(error instanceof Error ? error.message : t('network.errors.unknown'), {
        position: 'bottom-right',
        type: 'error',
      })
    },
  })
}

/**
 * Hook to check if a delivery is already rated
 */
export const useCheckDeliveryRated = () => {
  const [parseResult, setParseResult] = useState<TokenParseResult>()

  // Parse token on mount
  useEffect(() => {
    const result = parseDeliveryRatingToken()
    setParseResult(result)
  }, [])

  const { data, isLoading, error } = useQuery({
    queryKey: ['delivery-rated', parseResult?.token?.deliveryId],
    queryFn: async () => {
      if (!parseResult?.token?.deliveryId) throw new Error('No delivery ID provided')
      const response = await axios.get<{ rated: boolean }>(
        `${URL}rated/${parseResult.token.deliveryId}`,
      )
      return response.data
    },
    enabled: !!parseResult?.token?.deliveryId,
    retry: false, // Don't retry on 404s
  })

  return {
    rated: data?.rated,
    data: parseResult,
    isLoading,
    error,
  }
}
