import { type DateTime } from 'luxon'

import { type Coords } from '../components/map'
import { type Address } from './address.types'
import type Admin from './admin.types'
import { type Client } from './client.types'
import { type DeliveryPayment } from './deliveryPayment.types'
import type Driver from './driver.types'
import { type Offer } from './offer.types'
import { type Subscription } from './subscription.types'
import { type Vehicle } from './vehicle.types'
import { type Organization } from './Organization.types'
import { TCancelDelivery } from '@schemas/DeliveryForm/CancelDeliverySchema'

export enum DeliveryType {
  STANDARD = 'STANDARD',
  TO_RETURN = 'TO_RETURN',
  TWO_WAY_DELIVERY = 'TWO_WAY_DELIVERY',
  'OKM' = 'OKM',
  //QUALITY_CHECK = 'QUALITY_CHECK',
}
export type DeliveriesCount = {
  requestedCount?: number
  confirmedCount?: number
  onGoingCount?: number
  waitingDriverCount?: number
  setCount?: number
  alertCount?: number
  deliveredCount: number
  cancelledCount?: number
}

export enum DeliveryProgress {
  DELIVERED,
  PENDING,
  IN_PROGRESS,
}

export enum AdminDeliveryStats {
  ByStatus,
  ByType,
}

export enum TwoWayDelivery {
  TO_RETURN = 'TO_RETURN',
  EXCHANGE = 'EXCHANGE',
}

export enum DeliveryStatus {
  REQUESTED = 'REQUESTED',
  PENDING_CUSTOMER_CONFIRMATION = 'PENDING_CUSTOMER_CONFIRMATION',
  CONFIRMED_BY_CUSTOMER = 'CONFIRMED_BY_CUSTOMER',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  RESCHEDULED_BY_ADMIN = 'RESCHEDULED_BY_ADMIN',
  CANCELLED_BY_ADMIN = 'CANCELLED_BY_ADMIN',
  CONFIRMED = 'CONFIRMED',
  WAITING_FOR_DRIVER = 'WAITING_FOR_DRIVER',
  CANCELLED_BY_MANDATORY = 'CANCELLED_BY_MANDATORY',
  SET = 'SET',
  ALERT = 'ALERT',
  PICKUP_DRIVER = 'PICKUP_DRIVER',
  PICKUP_CLIENT = 'PICKUP_CLIENT',
  ONGOING = 'ONGOING',
  DELIVERED = 'DELIVERED',
  SMS_SENT = 'SMS_SENT',
  CANCELLED = 'CANCELLED',
  SWAP_PICKUP_DRIVER = 'SWAP_PICKUP_DRIVER',
  SWAP_IN_TRANSIT = 'SWAP_IN_TRANSIT',
  SWAP_PICKUP_CLIENT = 'SWAP_PICKUP_CLIENT',
  SWAP_DELIVERED = 'SWAP_DELIVERED',
  COMPLETED = 'COMPLETED',
}

export type DeliveryFormSectionProps = {
  handleInputChange: <K extends keyof Delivery>(changes: Delivery[K], identifier: K) => void
  formState: Delivery
  disabled: boolean
  errorFields: Array<keyof Delivery>
}
export type SelectedOption = {
  id: string
  name: string
  photo?: string
  price?: number
}
export type DeliveryOptions = JSON

export type ClientDashboardMonthDeliveryData = {
  totalPrice: number
  totalDistance: number
  totalDeliveries: number
  averagePrice: number
  averageDistance: number
}

export enum TCarType {
  A = 'A',
  B = 'B',
  C = 'C',
}

export type DeliveryLog = {
  id: string
  log: string
  createdAt: Date
  deliveryId?: string
}

export type Delivery = {
  id: number
  remainingHoursToEstimatedDate?: number // Resolve field
  totalHoursToEstimatedDate?: number // Resolve field
  clientId: number
  client: Client
  status: DeliveryStatus
  type: DeliveryType
  minPrice?: number
  maxPrice?: number
  twoWayDeliverType?: TwoWayDelivery
  carAvailabilityDate: DateTime | undefined
  carPickUpDate: DateTime | undefined
  carDeliveryDeadline: DateTime | undefined
  courtoiseReturnDate?: DateTime
  numberOfVehicles: number
  optionNameFilter?: string
  optionIdFilter?: string
  options: DeliveryOptions
  garageOptions: DeliveryOptions
  duration: number
  distance: number
  startCode: string
  destinationCode: string
  fromAddressId?: string
  fromAddress?: Address
  toReturnFromAddress?: Address
  fromAddressPrecision?: string
  toAddressId?: string
  toAddress?: Address
  toReturnToAddress?: Address
  toAddressPrecision?: string
  wantedPrice?: number
  finalPrice?: number
  clientInitialPrice?: number
  clientFinalPrice?: number
  comment?: string
  clientFirstName: string
  clientLastName: string
  clientEmail: string
  clientPhone: string
  clientSiret: string
  clientCompany?: string | undefined
  clientDrivingLicenceNumber?: string | undefined
  representativeFirstName: string
  representativeLastName: string
  representativeEmail: string
  representativePhone: string
  representativeSiret: string
  representativeCompany: string
  vehicles: Vehicle[]
  offers: Offer[]
  publicId: string // Resolve field
  isEditable?: boolean // ? resolve field
  createdAt: DateTime
  updatedAt?: DateTime
  departedAt?: DateTime
  completedAt?: DateTime
  confirmedAt?: DateTime
  putInAuctionAt?: DateTime
  toRetrieveVehicle?: Vehicle
  subscriptions: Subscription[]
  number_of_offers: number
  acceptedOffer?: Offer // Resolve field
  minOfferPrice?: number // Resolve field
  averageOffersPrice?: number // Resolve field
  // outfitRating?: number
  // justiceRating?: number
  // correspondenceRating?: number
  // takeOffRating?: number
  // qualityRating?: number
  // communicationRating?: number
  rated: boolean
  isPaid: boolean
  latestLocation?: Coords
  confirmedByAdminId?: number
  confirmedByAdmin?: Admin
  selectedDriverId?: number
  selectedDriver?: Driver
  smsId?: string
  smsSentAt?: DateTime
  confirmedByCustomerAt?: DateTime
  remainingDistance?: number
  payment?: DeliveryPayment
  category: 'STANDARD' | 'EXPRESS' | 'OKM'
  docs: Doc[]
  validationDocs: Doc[]
  organization: Organization
  deliveryOrganization: Organization
  organizationId: number
  invoice: string
  selectedOffer: Offer
  accepted_offer: Offer
  cancelledAt?: DateTime
  cancelReason?: TCancelDelivery
}
export type Doc = { name: string; file?: Blob; key?: string; url: string; deleted?: boolean }
export type CreateDeliveryInput = Omit<Delivery, 'id' | 'client' | 'docs'> & { docs: string } & {
  vehicles?: Vehicle[]
}
export type CalculateDeliveryPriceInput = {
  fromAddress: Address
  toAddress: Address
  carPickUpDate: DateTime | undefined
  options?: DeliveryOptions
  garageOptions?: DeliveryOptions
  numberOfVehicles: number
}
export type DeliveryEstimateData = {
  distance: number
  duration: number
  clientInitialPrice: number
  minPrice: number
  medianPrice: number
  maxPrice: number
}
