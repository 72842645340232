import { type HeadCell } from '@components/common/table/EnhancedTableHead'
import {
  adminAlertHeadCells,
  clientAlertHeadCells,
} from '@components/Delivery/DeliveryTable/HeadCells/alert'
import {
  adminDeliveredHeadCells,
  clientDeliveredHeadCells,
} from '@components/Delivery/DeliveryTable/HeadCells/delivered'
import {
  adminOngoingHeadCells,
  clientOngoingHeadCells,
} from '@components/Delivery/DeliveryTable/HeadCells/ongoing'
import {
  AdminRequestedHeadCells,
  ClientRequestedHeadCells,
} from '@components/Delivery/DeliveryTable/HeadCells/requested'
import {
  adminSetHeadCells,
  clientSetHeadCells,
} from '@components/Delivery/DeliveryTable/HeadCells/set'
import {
  AdminWaitingForDriverHeadCells,
  ClientWaitingForDriverHeadCells,
} from '@components/Delivery/DeliveryTable/HeadCells/waiting_for_driver'
import { type DeepInclude } from '@types_def/common/filter.types'
import { type QueryType } from '@types_def/common/query.types'
import { type Delivery, DeliveryStatus } from '@types_def/models/delivery.types'

import {
  adminConfirmedHeadCells,
  clientConfirmedHeadCells,
} from '../../../components/Delivery/DeliveryTable/HeadCells/confirmed'
import {
  adminCancelledHeadCells,
  clientCancelledHeadCells,
} from '@components/Delivery/DeliveryTable/HeadCells/cancelled'
export type DeliveryStatusAttributes = {
  key: DeliveryStatus
  title: string
  clientHeadCells: Array<HeadCell<Delivery>>
  adminHeadCells: Array<HeadCell<Delivery>>
  clientTableStatusFilter: DeliveryStatus[]
  adminTableStatusFilter: DeliveryStatus[]
  clientDeliveryInclude?: DeepInclude<Delivery>
  adminDeliveryInclude?: DeepInclude<Delivery>
  adminDeliveriesTableQueryFields: QueryType<Delivery>
  clientDeliveriesTableQueryFields: QueryType<Delivery>
}
const DeliveriesTableQueryFields: QueryType<Delivery> = {
  organizationId: true,
  id: true,
  clientId: true,
  carAvailabilityDate: true,
  carPickUpDate: true,
  carDeliveryDeadline: true,
  createdAt: true,
  duration: true,
  numberOfVehicles: true,
  distance: true,
  publicId: true,
  departedAt: true,
  completedAt: true,
  type: true,
  clientFinalPrice: true,
  clientInitialPrice: true,
  options: undefined,
  destinationCode: true,
  wantedPrice: true,
  finalPrice: true,
  clientLastName: true,
  status: true,
  confirmedAt: true,
  remainingDistance: true,
  fromAddress: {
    city: true,
    postalCode: true,
  },
  toAddress: {
    city: true,
    postalCode: true,
  },
  subscriptions: {
    name: true,
  },
  organization: {
    name: true,
    slug: true,
  },
  cancelledAt: true,
}
const DeliveriesTableQueryFieldsWithAcceptedOffer: QueryType<Delivery> = {
  ...DeliveriesTableQueryFields,
  startCode: true,
  selectedDriver: {
    fullName: true,
  },
}
export const deliveryStatusAttributes: DeliveryStatusAttributes[] = [
  {
    key: DeliveryStatus.REQUESTED,
    title: 'Nouvelle',
    adminHeadCells: AdminRequestedHeadCells,
    clientHeadCells: ClientRequestedHeadCells,
    clientTableStatusFilter: [
      DeliveryStatus.REQUESTED,
      DeliveryStatus.PENDING_CUSTOMER_CONFIRMATION,
      DeliveryStatus.CONFIRMED_BY_CUSTOMER,
      DeliveryStatus.RESCHEDULED_BY_ADMIN,
    ],
    adminTableStatusFilter: [
      DeliveryStatus.REQUESTED,
      DeliveryStatus.PENDING_CUSTOMER_CONFIRMATION,
      DeliveryStatus.CONFIRMED_BY_CUSTOMER,
      DeliveryStatus.CANCELLED_BY_CUSTOMER,
      DeliveryStatus.RESCHEDULED_BY_ADMIN,
    ],
    adminDeliveriesTableQueryFields: DeliveriesTableQueryFields,
    clientDeliveriesTableQueryFields: DeliveriesTableQueryFields,
    clientDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
    },
    adminDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
      organization: true,
    },
  },
  {
    key: DeliveryStatus.CANCELLED,
    title: 'Annuler',
    adminHeadCells: adminCancelledHeadCells,
    clientHeadCells: clientCancelledHeadCells,
    clientTableStatusFilter: [
      DeliveryStatus.CANCELLED_BY_ADMIN,
      DeliveryStatus.CANCELLED_BY_CUSTOMER,
      DeliveryStatus.CANCELLED_BY_MANDATORY,
    ],
    adminTableStatusFilter: [
      DeliveryStatus.CANCELLED_BY_ADMIN,
      DeliveryStatus.CANCELLED_BY_CUSTOMER,
      DeliveryStatus.CANCELLED_BY_MANDATORY,
    ],
    adminDeliveriesTableQueryFields: DeliveriesTableQueryFields,
    clientDeliveriesTableQueryFields: DeliveriesTableQueryFields,
    clientDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
      organization: true,
    },
    adminDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
      organization: true,
    },
  },
  {
    key: DeliveryStatus.ONGOING,
    title: 'En Cours',
    clientHeadCells: clientOngoingHeadCells,
    adminHeadCells: adminOngoingHeadCells,
    clientTableStatusFilter: [
      DeliveryStatus.ONGOING,
      DeliveryStatus.PICKUP_DRIVER,
      DeliveryStatus.PICKUP_CLIENT,
      DeliveryStatus.DELIVERED,
      DeliveryStatus.SWAP_DELIVERED,
      DeliveryStatus.SWAP_IN_TRANSIT,
      DeliveryStatus.SWAP_PICKUP_CLIENT,
      DeliveryStatus.SWAP_PICKUP_DRIVER,
    ],
    adminTableStatusFilter: [
      DeliveryStatus.ONGOING,
      DeliveryStatus.PICKUP_DRIVER,
      DeliveryStatus.PICKUP_CLIENT,
      DeliveryStatus.DELIVERED,
      DeliveryStatus.SWAP_DELIVERED,
      DeliveryStatus.SWAP_IN_TRANSIT,
      DeliveryStatus.SWAP_PICKUP_CLIENT,
      DeliveryStatus.SWAP_PICKUP_DRIVER,
    ],
    adminDeliveriesTableQueryFields: {
      ...DeliveriesTableQueryFieldsWithAcceptedOffer,
      subscriptions: {
        name: true,
        id: true,
      },
    },
    clientDeliveriesTableQueryFields: DeliveriesTableQueryFields,
    clientDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
    },
    adminDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
      subscriptions: true,
      organization: true,
      selectedDriver: true,
    },
  },
  {
    key: DeliveryStatus.WAITING_FOR_DRIVER,
    title: 'En attente',
    clientHeadCells: ClientWaitingForDriverHeadCells,
    adminHeadCells: AdminWaitingForDriverHeadCells,
    clientTableStatusFilter: [DeliveryStatus.WAITING_FOR_DRIVER],
    adminTableStatusFilter: [DeliveryStatus.WAITING_FOR_DRIVER],
    adminDeliveriesTableQueryFields: {
      ...DeliveriesTableQueryFields,
      subscriptions: {
        name: true,
        id: true,
      },
      number_of_offers: true,
      averageOffersPrice: true,
      minOfferPrice: true,
      remainingHoursToEstimatedDate: true,
      totalHoursToEstimatedDate: true,
      offers: {
        demandedPrice: true,
      },
    },
    clientDeliveriesTableQueryFields: DeliveriesTableQueryFields,
    clientDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
    },
    adminDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
      subscriptions: true,
      organization: true,
      offers: {
        include: {
          driver: true,
        },
      },
    },
  },
  {
    key: DeliveryStatus.ALERT,
    title: 'Alerte',
    clientHeadCells: clientAlertHeadCells,
    adminHeadCells: adminAlertHeadCells,
    clientTableStatusFilter: [DeliveryStatus.WAITING_FOR_DRIVER],
    adminTableStatusFilter: [DeliveryStatus.WAITING_FOR_DRIVER],
    adminDeliveriesTableQueryFields: DeliveriesTableQueryFields,
    clientDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
    },
    adminDeliveryInclude: {
      toAddress: true,
      subscriptions: true,
      fromAddress: true,
      organization: true,
    },
    clientDeliveriesTableQueryFields: DeliveriesTableQueryFields,
  },
  {
    key: DeliveryStatus.CONFIRMED,
    title: 'Confirmé',
    clientHeadCells: clientConfirmedHeadCells,
    adminHeadCells: adminConfirmedHeadCells,
    clientDeliveriesTableQueryFields: DeliveriesTableQueryFieldsWithAcceptedOffer,
    adminDeliveriesTableQueryFields: DeliveriesTableQueryFieldsWithAcceptedOffer,
    clientDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
    },
    adminDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
      organization: true,
      offers: {
        include: {
          driver: true,
        },
      },
    },
    clientTableStatusFilter: [
      DeliveryStatus.CONFIRMED,
      DeliveryStatus.WAITING_FOR_DRIVER,
      DeliveryStatus.SET,
    ],
    adminTableStatusFilter: [DeliveryStatus.CONFIRMED],
  },
  {
    key: DeliveryStatus.SET,
    title: 'Attribué',
    clientHeadCells: clientSetHeadCells,
    adminHeadCells: adminSetHeadCells,
    clientDeliveriesTableQueryFields: DeliveriesTableQueryFields,
    adminDeliveriesTableQueryFields: {
      ...DeliveriesTableQueryFieldsWithAcceptedOffer,
      subscriptions: {
        name: true,
        id: true,
      },
    },
    clientDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
    },
    adminDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
      subscriptions: true,
      organization: true,
      selectedDriver: true,
    },
    clientTableStatusFilter: [DeliveryStatus.SET],
    adminTableStatusFilter: [DeliveryStatus.SET],
  },
  {
    key: DeliveryStatus.COMPLETED,
    title: 'Livré',
    clientHeadCells: clientDeliveredHeadCells,
    adminHeadCells: adminDeliveredHeadCells,
    clientTableStatusFilter: [DeliveryStatus.COMPLETED],
    adminTableStatusFilter: [DeliveryStatus.COMPLETED],
    clientDeliveriesTableQueryFields: DeliveriesTableQueryFields,
    adminDeliveriesTableQueryFields: {
      ...DeliveriesTableQueryFieldsWithAcceptedOffer,
      subscriptions: {
        name: true,
        id: true,
      },
      invoice: true,
    },
    clientDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
    },
    adminDeliveryInclude: {
      toAddress: true,
      fromAddress: true,
      subscriptions: true,
      organization: true,
      offers: {
        include: {
          driver: true,
        },
      },
    },
  },
]
