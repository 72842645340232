import { Box, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Doc } from '@types_def/models/delivery.types'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'

type Props = {
  docs: Doc[]
  disabled?: boolean
  deletedFiles?: Doc[]
  changeFileState?: (file: Doc, deleted: boolean) => void
}

const getFileName = (key: string): string => {
  // Extract the filename from the path
  const parts = key.split('/')
  return parts[parts.length - 1]
}

const FilesGrid = ({ docs, changeFileState, disabled = false }: Props) => {
  const parsedDocs = typeof docs === 'string' ? JSON.parse(docs) : docs

  return (
    <>
      {docs?.length > 0 && (
        <Box
          component={'ul'}
          sx={{
            textDecoration: 'none',
            listStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            alignItems: 'flex-start',
            padding: 2,
          }}
        >
          {parsedDocs?.map((file: Doc) => {
            const fileName = file.key ? getFileName(file.key) : file.name
            return (
              <li
                key={`${file.key || file.name}-${file.url}`}
                id={'file'}
                style={{
                  textAlign: 'center',
                  width: 90,
                  minHeight: 120,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  position: 'relative',
                  padding: '8px',
                  border: '1px solid #eee',
                  borderRadius: '4px',
                }}
              >
                {changeFileState ? (
                  <IconButton
                    onClick={() => {
                      changeFileState(file, file?.deleted ? false : true)
                    }}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      height: '24px',
                      width: '24px',
                      cursor: disabled ? 'not-allowed' : 'pointer',
                    }}
                    aria-label='delete'
                    disabled={disabled}
                  >
                    {file?.deleted ? (
                      <SettingsBackupRestoreIcon fontSize='small' color={'success'} />
                    ) : (
                      <DeleteIcon fontSize='small' color={disabled ? 'disabled' : 'warning'} />
                    )}
                  </IconButton>
                ) : null}

                <Box
                  component='a'
                  href={file.url}
                  download
                  title={fileName}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  {file.key?.includes('.pdf') ? (
                    <img
                      src='https://upload.wikimedia.org/wikipedia/commons/4/42/Pdf-2127829.png'
                      alt='PDF Icon'
                      style={{ width: 65, opacity: file?.deleted ? 0.3 : 1 }}
                    />
                  ) : (
                    <img
                      src='/images/adobe.png'
                      alt={fileName}
                      style={{ width: 65, opacity: file?.deleted ? 0.3 : 1 }}
                    />
                  )}

                  <Tooltip title={fileName}>
                    <Box
                      component='span'
                      sx={{
                        display: 'block',
                        width: '100%',
                        maxWidth: '80px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontSize: '12px',
                        textAlign: 'center',
                        marginTop: '4px',
                        color: 'text.secondary',
                        lineHeight: '1.2',
                        padding: '0 4px',
                      }}
                    >
                      {fileName}
                    </Box>
                  </Tooltip>
                </Box>
              </li>
            )
          })}
        </Box>
      )}
    </>
  )
}

export default FilesGrid
