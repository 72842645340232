import React, { ReactNode, useState, isValidElement } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Box, Breakpoint, DialogTitle, SxProps, Theme } from '@mui/material'

type ButtonColors = 'success' | 'primary' | 'secondary' | 'inherit' | 'error' | 'info' | 'warning'
type ButtonVariants = 'text' | 'outlined' | 'contained'
type ButtonSizes = 'small' | 'medium' | 'large'
type ButtonTypes = 'button' | 'submit' | 'reset'

type Props = {
  /** Whether to show action buttons in the modal */
  noAction?: boolean
  /** Modal title */
  title?: string
  /** Control modal open state externally */
  open?: boolean
  /** Color of the trigger button */
  btnColor?: ButtonColors
  /** Whether the modal trigger button is disabled */
  disabled?: boolean
  /** Content of the trigger button */
  button?: ReactNode
  /** Modal content */
  children?: ReactNode
  /** Additional styles for the modal container */
  containerStyles?: SxProps<Theme>
  /** Additional styles for the trigger button */
  buttonStyles?: SxProps<Theme>
  /** Modal size */
  size?: Breakpoint
  /** Handler for confirm action. If returns a promise, modal will close after resolution */
  confirmAction?: () => void | Promise<void>
  /** Handler for cancel action */
  cancelAction?: () => void
  /** Form ID for form submission */
  formId?: string
  /** Text for confirm button */
  confirmText?: string
  /** Text for cancel button */
  cancelText?: string
  /** Whether the trigger button should be full width */
  fullWidth?: boolean
  /** Color of the trigger button */
  color?: ButtonColors
  /** Variant of the trigger button */
  variant?: ButtonVariants
  /** Whether clicking the trigger button opens the modal */
  canOpen?: boolean
  /** Whether to display the trigger button */
  display?: boolean
  /** Type of the confirm button */
  type?: ButtonTypes
  /** Size of the trigger button */
  btnSize?: ButtonSizes
}

const CustomModal = ({
  type = 'submit',
  title,
  disabled,
  children,
  formId,
  confirmAction,
  cancelAction,
  open: InitialOpen = false,
  button,
  size = 'md',
  buttonStyles,
  confirmText = 'Créer le compte',
  cancelText = 'Annuler',
  fullWidth,
  color = 'primary',
  variant = 'contained',
  canOpen = true,
  display = true,
  btnSize = 'medium',
  noAction = false,
}: Props) => {
  const [open, setOpen] = useState(InitialOpen)

  const handleClose = () => {
    if (cancelAction) {
      cancelAction()
    } else {
      setOpen(false)
    }
  }

  const handleButtonClick = () => {
    if (canOpen) {
      setOpen((v) => !v)
    } else if (confirmAction) {
      confirmAction()
    }
  }

  const handleConfirmAction = async () => {
    if (confirmAction) {
      await confirmAction()
      setOpen(false)
    }
  }

  return (
    <>
      {isValidElement(button) ? (
        <Box
          sx={{ display: display ? 'flex' : 'none', ...buttonStyles }}
          onClick={handleButtonClick}
        >
          {button}
        </Box>
      ) : (
        <Button
          sx={{ display: display ? 'flex' : 'none', ...buttonStyles }}
          size={btnSize}
          fullWidth={fullWidth}
          disabled={disabled}
          onClick={handleButtonClick}
          color={color}
          variant={variant}
        >
          {button}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth={size}
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        {noAction ? null : (
          <DialogActions>
            <Box sx={{ gap: 2, padding: 1, width: '100%', display: 'flex' }}>
              <Button
                form={formId}
                type='reset'
                color='warning'
                variant='outlined'
                fullWidth
                onClick={handleClose}
              >
                {cancelText}
              </Button>
              <Button
                form={formId}
                type={type}
                onClick={handleConfirmAction}
                variant='contained'
                disableElevation
                color='success'
                fullWidth
              >
                {confirmText}
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

export default CustomModal
